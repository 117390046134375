import React, { useState, useEffect } from 'react'

const ActionStrip = ({allAgents, ticketTypes, updateTicket, originalStatus, originalAssignedTo, originalTicketType, originalPriority, isSubmitting}) => {
    
    const [agent, setAgent] = useState(""); 
    const [ticketType, setTicketType] = useState(""); 
    const [ticketStatus, setTicketStatus] = useState("");
    const [ticketPriority, setTicketPriority] = useState("");
    
    
    useEffect(() => { 
        setAgent(originalAssignedTo);
    }, [originalAssignedTo]); 

    useEffect(() => {
        console.log(originalTicketType);
        setTicketType(originalTicketType);
    }, [originalTicketType]);

    useEffect(() => {
        setTicketStatus(originalStatus);
    }, [originalStatus]);

    useEffect(() => {
        setTicketPriority(originalPriority);
    }, [originalPriority]);

  return (
    <div className='bg-light p-3 input-field-ui'>   
        <h2>Update Status</h2>
       <div className='row'>
          <div className='col-md-3'>
                <div className='align-items-center'>
                    <label>Assign To</label>
                    <select className="form-select tw-w-90" 
                     name='assignTo' 
                     aria-label="Default select example"
                     value={agent}
                     onChange={(e) => setAgent(e.target.value)}
                     >
                       { 
                        allAgents.map((agent) => (
                            <option key={agent.id} value={agent.id}>{agent.name}</option>
                        ))
                       }
                    </select>
                </div>
          </div>

          <div className='col-md-3'>
                <div className=''>
                    <label>Change Type</label>

                    <select className="form-select" name='status' aria-label="Default select example"
                    value={ticketType}
                    onChange={e => setTicketType(e.target.value)}
                    >
                        { 
                            ticketTypes.map((ticketType) => (
                                <option key={ticketType.value} value={ticketType.value}>{ticketType.label}</option>
                            ))
                        }
                    </select>
                </div>
          </div>


          <div className='col-md-3'>
                <div className=''>
                    <label>Change Priority</label>

                    <select className="form-select" name='priority' aria-label="Default select example"
                    value={ticketPriority}
                    onChange={e => setTicketPriority(e.target.value)}
                    >
                        <option value="blocker">Blocker</option>
                        <option value="major">Major</option>
                        <option value="minor">Minor</option>
                        <option value="good_to_have">Good-to-have</option>
                    </select>
                </div>
          </div>


          <div className='col-md-3'>
                <div className=''>
                    <label>Change Ticket Status</label>
                    <select className="form-select" name='status' aria-label="Default select example"
                    value={ticketStatus}
                    onChange={e => setTicketStatus(e.target.value)}
                    >
                        <option value="new">New</option>
                        <option value="assigned">Assigned</option>
                        <option value="resolved">Resolved</option>
                        <option value="reopen">Reopen</option>
                        <option value="closed">Closed</option>
                    </select>
                </div>
          </div>

          <div> 
                <button   
                    type="button" 
                    id="xlsxSubmit" 
                    className="btn btn-success uplaod-file-btn mt-2"
                    onClick={e => updateTicket(ticketStatus, agent, ticketType, ticketPriority)}
                >
                    { 
                        isSubmitting ? 
                        <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Submitting...
                        </>: 
                        "Submit"
                    }
                    </button>
            </div>  
       </div>
    </div>
  )
}

export default ActionStrip
