import React, { useState, useEffect, useLayoutEffect } from 'react'; 

const TicketDetails = ({ticketId, fetch_single_ticket, ticket, setTicket, rerenderSingleTicket=false}) => {

    const [isLoading, setIsLoading] = useState(false); 
    // const [ticket, setTicket] = useState({});

    useLayoutEffect(() => { 
        if(ticketId){ 
            fetchSingleTicket(); 
        }
    }, [ticketId, rerenderSingleTicket]); 
    

    async function  fetchSingleTicket() {
        setIsLoading(true);
        try {
            let response = await fetch_single_ticket(ticketId);

            if (response.data?.code === 200) {
                setTicket(response.data.data?.ticket);
            }
        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    }



  return (
    <div className="observation-wrapper">
    <div className='profile-card mb-3'>
        <div className='row'>
            <div className='col-md-auto'>
                <div className="profile-image">
                    <img src={"https://appcdn.goqii.com/user/user/l_1437644704.png"} alt="Profile Image" style={{ cursor: 'pointer' }} />
                </div>
            </div>

            <div className='col-md ps-2'>
                <div className='row'>
                    <div className='col-md'>
                        <div className="profile-info">
                            <div className="info-line">
                                <div className="info">
                                    <a className="name">{ticket?.memberInfo?.memberName}</a>
                                    <span className="location">
                                        <i className="fa fa-map-marker" aria-hidden="true"></i> {ticket?.memberInfo?.city}
                                    </span>
                                </div>
                            </div>

                            <div className="info-line">
                                <div className="info">
                                    <span className="gender big-text me-3">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" style={{ height: "18px" }} alt="" />
                                        </i>  {ticket?.member?.gender === "F" ? "Female" : "Male"} {ticket?.memberInfo?.age}
                                    </span>
                                    <span className="physical big-text me-3">
                                        <i className="" aria-hidden="true">
                                            <img src={ticket?.memberInfo?.imageUrl? ticket?.memberInfo?.imageUrl : "https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png"} style={{ height: "18px" }} alt="" />
                                        </i> {ticket?.memberInfo?.height} cms
                                    </span>
                                    <span className="physical big-text me-3">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" />
                                        </i> {ticket?.memberInfo?.weight} kgs
                                    </span>
                                    <span className="physical big-text">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" />
                                        </i> 22.9
                                    </span>
                                    <span className="physical big-text">
                                        <i className="" aria-hidden="true">
                                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/qem1c/m9x870b478.png" style={{ height: "18px" }} alt="" />
                                        </i> 5.4
                                    </span>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info">
                                    <span className="email">
                                        <i className="fa fa-envelope pad-right-10" aria-hidden="true"></i> {ticket?.memberInfo?.email}
                                    </span>
                                    <span className="phone">
                                        <i className="fa fa-phone pad-right-10" aria-hidden="true"></i> {ticket?.memberInfo?.mobile}
                                    </span>
                                    { 
                                        ticket?.memberInfo?.altMobile && 
                                        <span className="phone">
                                            <i className="fa fa-phone pad-right-10" aria-hidden="true"></i> { ticket?.memberInfo?.altMobile}
                                        </span>
                                    }
                                </div>
                            </div>
                            <hr className='my-2' />
                            <div className="info-line">
                                <div className="info row">
                                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-2 tw-w-1/2'>
                                        <h5 className="membership-id tw-mb-2"><b>Membership:</b> {ticket?.memberInfo?.membershipNumber}</h5>
                                    </div>
                                    <h5 className="membership-id col-6 tw-mb-0 tw-pt-2"><b>Care Navigator:</b> {ticket?.memberInfo?.carenavigatorName}</h5>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info row">
                                    <h5 className="membership-id col-6 mb-2"><b>Main Membership:</b> {ticket?.memberInfo?.mainMembershipNo}</h5>
                                    <h5 className="membership-id col-6 mb-2"><b>Membership Type:</b> {ticket?.memberInfo?.policyMemberType}</h5>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info row">
                                    <h5 className="dates col-6 mb-2"><b>Policy Start Date:</b> {ticket?.memberInfo?.policyStartDate}</h5>
                                    <h5 className="dates col-6 mb-2"><b>Policy End Date:</b> {ticket?.memberInfo?.policyEndDate}</h5>
                                </div>
                            </div>
                            <div className="info-line">
                                <div className="info row">
                                    <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {ticket?.memberInfo?.contractNumber}</h5>
                                    <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {ticket?.memberInfo?.contractName} </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-md-3 ps-0'>
        <div className='row tag-height-d'>
            <div className='col-12'>
                <label className="membership-id mb-1">Family Member:</label>
                <select className="service-action" value="">
                    <option value="">Select</option>
                    <option value="1">Alice Doe</option>
                    <option value="2">Bob Doe</option>
                </select>
            </div>
            <div className='col-12'>
                <div className="profile-info">
                    <div className="risk-badge">
                        <div className="risk-circle" style={{ backgroundColor: "#ff0000" }}>75<br /><span>HRA</span></div>
                        <div className="risk-label" style={{ color: "#ff0000" }}>High Risk</div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
                </div>
            </div>
        </div>
    </div>


    <div className="row text-center px-2">
        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Ticket Id :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.ticket_id}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Membership No :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.membershipNo}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Raised By :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.raisedBy}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Status :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.status}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Priority :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.priority}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Ticket Type :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.ticketType}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Raised On :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.createdTime}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-md-3 px-1 mb-2">
            <div className="card bg-grey" style={{ borderRadius: '14px', border: 'none', height: '100%' }}>
                <div className="card-body" style={{ padding: '14px' }}>
                    <div className="row">
                        <div className="col">
                            <h5 className="card-text1 mb-1">Last Action Date :</h5>
                            <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>{ticket?.lastActionTime}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="tw-w-full">
        <div className="tw-p-4">
            <div className="tw-mb-6">
                <h5 className="tw-text-lg tw-font-medium tw-mb-2">Description:</h5>
                <p className="tw-text-gray-700 tw-break-words tw-w-full">
                    {ticket?.description}
                </p>
            </div>

            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
                {
                    ticket?.attachments?.map((attachment, index) => (
                        <div className="tw-flex tw-items-center tw-gap-3" key={attachment.id}>
                            <i className="bi bi-file-earmark-text"></i>
                            <div className="tw-flex-1 tw-min-w-0">
                                <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">sample_file.txt</p>
                                <p className="tw-text-xs tw-text-gray-500">File</p>
                            </div>
                            <a href={attachment.file_path} download className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200 tw-transition-colors">
                                <i className="bi bi-download tw-w-5 tw-h-5 tw-text-gray-600"></i>
                            </a>
                        </div>

                    ))
                }
            </div>

            

        </div>
    </div>
</div>
  )
}

export default TicketDetails
