import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { fetch_single_ticket, fetch_ticket_thread, comment_on_ticket, all_hcc_agents_list, fetch_all_ticket_types, update_ticket, fetch_ticket_history } from '../../../../services/hcc/Service';
import CommentForm from '../../Components/CommentForm/CommentForm';
import TicketDetails from '../../Components/TicketDetails/TicketDetails';
import { Download, Image, Video, FileText, File } from 'lucide-react';
import { capitalizeFirstLetter } from '../../../utils/Utils';
import ActionStrip from '../../Components/ActionStrip/ActionStrip';
import { ToastContainer, toast } from 'react-toastify';

const ViewTicket = () => {

    const [ticket, setTicket] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [ticketThread, setTicketThread] = useState([]); 
    const [allAgents, setAllAgents] = useState([]); 
    const [ticketTypes, setTicketTypes] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [rerenderSingleTicket, setRerenderSingleTicket] = useState(false); 
      const [ticketHistory, setTicketHistory] = useState([]); 
    const navigate = useNavigate();
    const { ticketId } = useParams();


    useEffect(() => { 
        fetchTicketThread(); 
        fetchAllHccAgents();
        fetchAllHccTicketTypes(); 
        fetchTicketHistory(); 
    }, []); 

    function navigateToBack() {
        navigate('/all-tickets');
    }


    const getFileIcon = (fileType) => {
    if (fileType.match(/\.(jpg|jpeg|png|gif|webp)$/)) return <Image className="tw-w-6 tw-h-6" />;
        if (fileType.match(/\.(mp4|webm|mov|ogg)$/)) return <Video className="tw-w-6 tw-h-6" />;
        if (fileType.match(/\.pdf$/)) return <FileText className="tw-w-6 tw-h-6" />;
        return <File className="tw-w-6 tw-h-6" />;
    };

    const getFileName = (filepath) => {
        return filepath.split('/').pop();
    };


    async function fetchTicketThread() {
        setIsLoading(true);
        try {
            let response = await fetch_ticket_thread(ticketId);
            if (response.data?.code === 200) {
                setTicketThread(response.data?.data?.comments);
            }
        } catch (e) {

        } finally {
            setIsLoading(false);
        }
    }

     async function fetchAllHccAgents(){ 
          try{  
            let response = await all_hcc_agents_list(); 
    
            if(response.data.code === 200){
              setAllAgents(response?.data?.data?.agents); 
            }
          }catch(e){
            
          }
        }

        async function fetchAllHccTicketTypes (){
          try{ 
            const response = await fetch_all_ticket_types();
    
            if(response.data.code === 200){ 
              const data = response.data?.data?.ticketTypes;  
              const ticketTypes = data.map(ticketType => ({ value: ticketType.id, label: ticketType.ticket_type }));
              setTicketTypes(ticketTypes); 
            }
          }catch(e){
            const errorResponse = e?.response?.data;
    
            if(errorResponse?.code < 500){
              // setError(errorResponse.data.message || "Something went wrong");
            }else { 
              // setError("Something went wrong");
            }
          }
      }


      async function updateTicket(updatedStatus, updatedAgent, updatedTicketType, updatedPriority) {
        setIsSubmitting(true);   

        let requestObj = {}; 

        if(updatedStatus !== ticket.originalStatus){
          requestObj.status = updatedStatus;
        }

        if(updatedAgent !== ticket.originalAssignedTo){
          requestObj.agent = updatedAgent;
        }

        if(updatedTicketType !== ticket.originalTicketType){
          requestObj.ticketType = updatedTicketType;
        }

        if(updatedPriority !== ticket.originalPriority){
          requestObj.priority = updatedPriority;
        }        

        try{ 
          let response = await update_ticket({ticketId, ...requestObj});

          if(response?.data?.code === 200){
              setRerenderSingleTicket(!rerenderSingleTicket); 
              fetchTicketHistory(); 
              toast.success(response?.data?.data?.message, {position: 'top-right'}); 
          }
        }catch(e){ 
          toast.success("Something went wrong", {position: 'top-right'}); 
        }finally{ 
          setIsSubmitting(false); 
        }
      }


      async function fetchTicketHistory(){ 
        setIsLoading(true); 
        try{ 
          let response = await fetch_ticket_history({ticketId});
          if(response.data?.code === 200){
            setTicketHistory(response.data?.data?.history || []); 
          }else { 
            setTicketHistory([]);
          }
        }catch(e){ 

        }finally{ 
          setIsLoading(false); 
        }
      }



    return (
        <div className="container-fluid">
            <ToastContainer />
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{ display: 'flex', alignItems: 'center' }}>
                    <button className="btn btn-primary back-btn mb-0 me-3" onClick={navigateToBack}>
                        <i className="bi bi-chevron-left me-1"></i> Back
                    </button>
                    <div className="col">
                        <h4 className="mb-0 content-main-title">{ticket.title}</h4>
                    </div>
                </div>
            </div>

            <div className="content-ProgramPerformance" style={{ marginBottom: "70px" }}>
                <TicketDetails ticket={ticket} setTicket={setTicket} ticketId={ticketId} fetch_single_ticket={fetch_single_ticket} rerenderSingleTicket={rerenderSingleTicket} />
                <hr className="tw-my-6 tw-border-dark-200" />
                {/* <div className='ticket-thread'>
                    <div className="observation-content w-100 px-3">
                        <div className="comment-wrapper">
                            <div className="comment-info">
                                <div className="comment-author mb-2">
                                    <div className="avatar comment-avatar">
                                        <img
                                            src="https://storage.googleapis.com/ksabupatest/2024/10/30/v9wj7/74g44s3273.webp"
                                            alt=""
                                            className="user-avatar"
                                        />
                                    </div>

                                    <div className="comment-meta">
                                        <span title="Javed Ali">
                                            <span>
                                                Javed Ali (Business)
                                            </span>
                                        </span>

                                        <ul className="meta-group">
                                            <li className="meta-data">
                                                <time datetime="2024-10-09T14:14:25Z" title="2024-10-09 19:44" data-datetime="relative">
                                                    2024-10-09 19:44
                                                </time>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <section className="comment-body">
                                    <div className="zd-comment ps-5" dir="auto">
                                        <p dir="auto" style={{ wordWrap: 'break-word', width: '100%' }}>
                                            This is a sample comment text that can be static.
                                        </p>
                                        <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-3 tw-mt-3">
                                            <div className="tw-max-w-[300px]">
                                                <div className="tw-relative tw-group">
                                                    <embed
                                                        src="https://example.com/sample.pdf"
                                                        type="application/pdf"
                                                        className="tw-w-full tw-h-[600px]"
                                                    />
                                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                                        <a
                                                            href="https://example.com/sample.pdf"
                                                            download="sample.pdf"
                                                            target='_blank'
                                                            className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                                        >
                                                            <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tw-max-w-[300px]">
                                                <div className="tw-relative tw-group">
                                                    <video controls className="tw-w-full tw-h-auto">
                                                        <source
                                                            src="https://example.com/sample.mp4"
                                                            type="video/mp4"
                                                        />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                                        <a
                                                            href="https://example.com/sample.mp4"
                                                            download="sample.mp4"
                                                            target='_blank'
                                                            className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                                        >
                                                            <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tw-max-w-[300px]">
                                                <div className="tw-relative tw-group">
                                                    <img
                                                        src="https://example.com/sample.jpg"
                                                        alt="attachment"
                                                        className="tw-w-full tw-h-[200px] tw-object-cover"
                                                    />
                                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                                        <a
                                                            href="https://example.com/sample.jpg"
                                                            download="sample.jpg"
                                                            target="_blank"
                                                            className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                                        >
                                                            <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                        <hr />
                    </div>

                </div> */}

                    {
                            ticketThread.length > 0 && 
                            ticketThread.map((item, index) => (
                              <div className='observation-content w-100 px-3'>
                                <div className="comment-wrapper">
                                  <div className="comment-info">
                                    <div className="comment-author mb-2">
                                      <div className="avatar comment-avatar">
                                        <img
                                          src="https://storage.googleapis.com/ksabupatest/2024/10/30/v9wj7/74g44s3273.webp"
                                          alt=""
                                          className="user-avatar"
                                        />
                                      </div>
                
                                      <div className="comment-meta">
                                        <span title="Javed Ali">
                                          <span>
                                            {capitalizeFirstLetter(item.firstname) + ' ' + capitalizeFirstLetter(item.lastname) + ' (' + `${item.commentBy === 'hcc_agent'? 'HCC Agent': 'Carenavigator'}` + ')'}
                                          </span>
                                        </span>
                
                                        <ul className="meta-group">
                                          <li className="meta-data">
                                            <time datetime="2024-10-09T14:14:25Z" title="2024-10-09 19:44" data-datetime="relative">
                                              {item.createdTime}
                                            </time>
                                          </li> 
                                        </ul>
                                      </div>
                                    </div>
                
                                    <section className="comment-body">
                                      <div className="zd-comment ps-5" dir="auto">
                                        <p dir="auto" style={{wordWrap: 'break-word', width: '100%'}}>{item.comment}</p>
                                        {
                                          item.attachments?.length ? (
                                            <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-3 tw-mt-3">
                                            {item?.attachments.map((attachment, index) => (
                                              <div key={index} className="tw-max-w-[300px]">
                                                {attachment.toLowerCase().endsWith('.pdf') ? (
                                                  <div className="tw-relative tw-group">
                                                    <embed 
                                                      src={attachment} 
                                                      type="application/pdf" 
                                                      className="tw-w-full tw-h-[600px]"
                                                    />
                                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                                      <a
                                                        href={attachment}
                                                        download={attachment.split('/').pop()}
                                                        target='_blank'
                                                        className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                                      >
                                                        <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                ) : attachment.toLowerCase().match(/\.(mp4|webm|ogg)$/) ? (
                                                  <div className="tw-relative tw-group">
                                                    <video controls className="tw-w-full tw-h-auto">
                                                      <source 
                                                        src={attachment} 
                                                        type={`video/${attachment.split('.').pop()}`} 
                                                      />
                                                      Your browser does not support the video tag.
                                                    </video>
                                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                                      <a
                                                        href={attachment}
                                                        download={attachment.split('/').pop()}
                                                        target='_blank'
                                                        className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                                      >
                                                        <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                ) : attachment.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) ? (
                                                  <div className="tw-relative tw-group">
                                                    <img 
                                                      src={attachment} 
                                                      alt="attachment" 
                                                      className="tw-w-full tw-h-[200px] tw-object-cover"
                                                    />
                                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                                      <a
                                                        href={attachment}
                                                        download={attachment.split('/').pop()}
                                                        target="_blank"
                                                        className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                                      >
                                                        <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4 tw-shadow-sm tw-flex tw-items-center tw-gap-3">
                                                    {getFileIcon(attachment.toLowerCase())}
                                                    <div className="tw-flex-1 tw-min-w-0">
                                                      <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">
                                                        {getFileName(attachment)}
                                                      </p>
                                                    </div>
                                                    <a
                                                      href={attachment}
                                                      download
                                                      target='_blank'
                                                      className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200 tw-transition-colors"
                                                    >
                                                      <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                                    </a>
                                                  </div>
                                                )}
                                              </div>
                                            ))}
                                          </div>
                                          ) : null
                                        }
                                      </div>
                                    </section>
                                  </div>
                                </div>
                                <hr />
                              </div>  
                            ))
                          }

                <CommentForm
                    ticketId={ticketId}
                    commentBy={"hcc_agent"}
                    comment_on_ticket={comment_on_ticket}
                    fetchTicketThread={fetchTicketThread}
                    fetchTicketHistory={fetchTicketHistory}
                />

                <hr className="tw-my-6 tw-border-dark-200" />                 

                <div className='action-strip'>
                    <ActionStrip allAgents={allAgents} ticketTypes={ticketTypes} updateTicket={updateTicket} originalPriority={ticket.originalPriority} originalAssignedTo={ticket.originalAssignedTo} originalStatus={ticket.originalStatus} originalTicketType={ticket.originalTicketType} isSubmitting={isSubmitting}
                    ticket={ticket}
                    />
                </div>


                <div className='row mt-4 px-2'>
                            <div className='col-md-12'>
                              <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>
                                Ticket History
                              </h5>
                              <div className="table-responsive mb-3">
                                
                                  <table className="table table-striped mb-0">
                                    <thead>
                                      <tr>
                                        <th style={{backgroundColor:"rgb(212, 230, 239)", fontSize:"14px", fontWeight:"500"}}>Date Modified</th>
                                        <th style={{backgroundColor:"rgb(212, 230, 239)", fontSize:"14px", fontWeight:"500"}}>Username</th> 
                                        <th style={{backgroundColor:"rgb(212, 230, 239)", fontSize:"14px", fontWeight:"500"}}>Comment</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {ticketHistory.map((history, index) => (
                                        <tr key={index}>
                                          <td style={{fontSize:"13px"}}>{history.createdTime}</td>
                                          <td style={{fontSize:"13px"}}>
                                         { history.name}  {`( ${history.commentBy === 'hcc_agent'? 'HCC Agent': 'Carenavigator'} )`}
                                          </td>
                                          <td style={{fontSize:"13px"}}>{history.comments}</td>
                                        </tr>
                                      ))}
                                    </tbody>  
                                  </table>
                                
                              </div>
                            </div>
                          </div>
            </div>
        </div>

    )
}

export default ViewTicket
