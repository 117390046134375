import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import './MemberProfile.css';
import TimelineProfile from './TimelineProfile';
import './TimelineProfile.css'; // Import the CSS file
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { CustomLoader, exportTableData } from '../utils/CommonLibrary';
import { fetchMember360Profile } from '../../services/medengage/Service';
import { NotificationToast, showToast } from '../componenents/ContentCareNavigator/NotificationToast';
import HealthLockerMP from './HealthLockerMP';


function MemberProfile() {

  //Health Locker
  const [showHealthLockerModal, setShowHealthLockerModal] = useState(false);
  const handleShowHealthLockerModal = () => {
    setShowHealthLockerModal(true);
  }
  const handleCloseHealthLockerModal = () => setShowHealthLockerModal(false);

  //==============

  const [searchStr, setSearchStr] = useState("");
  const [eventsFound, setEventsFound] = useState(true);
  const [memberBasicInfo, setMemberBasicInfo] = useState([]);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [memberPathways, setMemberPathways] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [distinctActions, setDistinctActions] = useState([]);
  const [filterText, setFilterText] = useState([]);
  const [showTelehealth, setShowTelehealth] = useState("N");
  const [showHealthLocker, setShowHealthLocker] = useState("N");

  useEffect(() => {
    if (filterText.length === 0) {
      setEvents(allEvents);
    } else {
      setEvents(allEvents.filter(event => filterText.includes(event.action)));
    }
  }, [filterText, allEvents]);

  const handleSubmit = async (event) => {
    setEventsFound(true);
    if (event) event.preventDefault();
    const searchString = document.getElementById("searchStr").value.trim();
    setShowUserProfile(false);
    if ((searchStr).trim() !== "") {
      setLoading(true);
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }

      await fetchMember360Profile(searchString)
        .then((response) => {
          if (response.data.code === 200) {
            setShowUserProfile(true);
            setMemberBasicInfo(response.data.data.info);
            setMemberPathways(response.data.data.info.memberPathways);
            setFamilyMembers(response.data.data.info.family);
            setEvents(response.data.data.info.events);
            setAllEvents(response.data.data.info.events);
            setShowTelehealth(response.data.data.info.showTelehealth);
            setShowHealthLocker(response.data.data.info.showHealthLocker);

            if (response.data.data.info.events.length <= 0) {
              setEventsFound(false);
            } else {
              setEventsFound(true);
            }

            const da = [...new Set(response.data.data.info.events.map(event => event.action))].sort();
            setDistinctActions(da);

          } else {
            showToast(response.data.data.message, 'error', 'top-right');
          }
          setLoading(false);
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      showToast("Please enter member ID", 'error', 'top-right');
    }
  }

  const handleExport = () => {
    const profileData = [
      {
        'Name': memberBasicInfo.memberName,
        'Email': memberBasicInfo.email
      }
    ]
    const modifiedData = events.map(item => {
      return {
        'Date': item.readableDate,
        'Action': item.action,
        'Description': item.description
      };
    });
    const mergedData = [...profileData, ...modifiedData];

    exportTableData("MemberProfile_" + memberBasicInfo.membershipNumber + "_", modifiedData);
  }

  const handleFamilyMemberProfileClick = (membershipNumber) => {
    setSearchStr(membershipNumber);
    document.getElementById("searchStr").value = membershipNumber.trim();
    document.getElementById("submitButton").click();
  }

  const handleTagClick = (item) => {
    setFilterText((prevFilterText) => {
      if (prevFilterText.includes(item)) {
        return prevFilterText.filter((i) => i !== item);
      } else {
        return [...prevFilterText, item];
      }
    });
  };

  return (
    <>
      {/* Uncomment Navbar if needed */}
      {/* <Navbar /> */}
      <NotificationToast />
      {
        loading === true ? <CustomLoader /> : ""
      }

      <section className='ReportsPrograms'>
        <div className="container-fluid px-0 mb-3 ">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Member Journey
              </h1>
            </div>
          </div>

          <div className='reports-programs mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wul4w/gh7ss4yhho.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Get a quick view of the member's journey, key milestones, and interaction history for easy tracking of their progress and engagement.</p>
              </div>
            </div>
          </div>

          <div id="content-wrapper" className="ContentDashboard">
            <div className=" mb-3">

              <div className='row'>
                <form className='row' method="post" id="searchMemberForm" onSubmit={handleSubmit}>
                  <div className='col-md-4 mt-2 pe-0'>
                    <div class="form-group">
                      <input type="text" className='form-control input-field-ui ps-5' placeholder="Search by Member ID" value={searchStr} onChange={(e) => setSearchStr(e.target.value.trim())} name="searchStr" id="searchStr" />
                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" class="input-icon-seacrh" alt="" />
                    </div>

                  </div>
                  <div className='col-md-3 mt-2'>
                    <button id="submitButton" className="btn btn-primary add-new-program-btn me-2" type="submit">Search</button>
                    {' '}
                    {
                      showUserProfile &&
                      <button className="btn btn-primary add-new-program-btn" type="button" onClick={() => {
                        handleExport();
                      }}><i className="bi bi-download"></i> Export</button>
                    }
                  </div>
                </form>
              </div>
              {
                showUserProfile === true &&
                <div className='row'>
                  <div className='col-md-12 mt-3'>
                    <div className="profile-card-new">
                      <div className='row'>
                        {/* <div className='col-md-auto'>
                      <div className="profile-image-1">
                        <img src="https://appcdn.goqii.com/user/user/l_1437644704.png" alt="Profile Image" />
                      </div>
                    </div> */}
                        <div className='col-md'>
                          <div className='row'>
                            <div className='col-md'>
                              <div className="profile-info">
                                <div className="info-line">
                                  <div className="info">
                                    <a className="name">{memberBasicInfo.memberName}</a>
                                    <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                      {memberBasicInfo.city}</span>
                                  </div>
                                </div>

                                <div className="info-line">
                                  <div className="info">
                                    <span className="gender big-text me-3">
                                      <i className="" aria-hidden="true">
                                        <img src={memberBasicInfo.gender === "M" ? "https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" : "https://storage.googleapis.com/ksabupatest/2024/09/24/hhqe5/gl8hw4hhl9.png"} style={{ height: "18px" }} alt="" />
                                      </i> {memberBasicInfo.gender === "M" ? "Male" : "Female"} {memberBasicInfo.age}
                                    </span>
                                    <span className="physical big-text me-3">
                                      <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.height} cms
                                    </span>
                                    <span className="physical big-text me-3">
                                      <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.weight} kgs
                                    </span>
                                    <span className="physical big-text">
                                      <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.bmi}
                                    </span>
                                  </div>
                                </div>

                                <div className="info-line">
                                  <div className="info">
                                    <span className="email"><i className="fa fa-envelope pad-right-10" aria-hidden="true"></i>{memberBasicInfo.email}</span>
                                    <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                      {memberBasicInfo.mobile}</span>
                                    <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                      {memberBasicInfo.altMobile ? memberBasicInfo.altMobile : "-"}</span>

                                  </div>
                                </div>
                                <hr className='my-3' />
                                <div className="info-line">
                                  <div className="info row">
                                    <h5 className="membership-id col-3 mb-2"><b>Membership:</b> {memberBasicInfo.membershipNumber}</h5>
                                    <h5 className="membership-id col-3 mb-2"><b>Care Navigator:</b> {memberBasicInfo.carenavigatorName ? memberBasicInfo.carenavigatorName : "-"}</h5>
                                    <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                  </div>
                                </div>
                                <div className="info-line">
                                  <div className="info row">
                                    <h5 className="membership-id col-3 mb-2"><b>Main Membership:</b> {memberBasicInfo.mainMembershipNumber}</h5>
                                    <h5 className="membership-id col-3 mb-2"><b>Membership Type:</b> {memberBasicInfo.policyMemberType ? memberBasicInfo.policyMemberType : "-"}</h5>
                                    <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                                  </div>
                                </div>
                                <div className="info-line">
                                  <div className="info row">
                                    <h5 className="dates col-3 mb-2"><b>Policy Start Date:</b> {memberBasicInfo.policyStartDate} </h5>
                                    <h5 className="dates col-3 mb-2"><b>Policy End Date:</b> {memberBasicInfo.policyEndDate}</h5>
                                  </div>
                                </div>
                                {/* <div className="info-line">
                              <div className="info row">
                                <h5 className="membership-id col-3 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                <h5 className="dates col-3 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {
                    (showTelehealth == "Y" || showHealthLocker == "Y") &&
                    <div className='col-md-12 mt-3'>
                      <div className="button-row">
                        {
                          showTelehealth == "Y" && <button className="action-button blue-btn action-btn-active">Telehealth Consultation</button>
                        }
                        {
                          showHealthLocker == "Y" && <button className="action-button blue-btn " onClick={handleShowHealthLockerModal}>Health Locker</button>
                        }
                      </div>
                    </div>
                  }


                  {
                    memberPathways.length > 0 &&
                    <div className='col-md-12 mt-3'>
                      <div className='profile-card-new pb-1'>
                        <div className='row'>

                          {
                            memberPathways.map((item, index) => {
                              return <>

                                <div className='col-md-3 mb-2 px-2'>
                                  <div className='profile-box-n1'>

                                    <div className="info-line row">
                                      <div className='col'>
                                        <h3 className='profile-card-title mb-2'>{item.programName}</h3>
                                        <h5 className="program" ><b>Condition:</b><br /> {item.conditionName}</h5>
                                        <div className="info-line mt-2">
                                          <div className='row'>
                                            <div className='col'>
                                              <h5 className="status mb-0"><b>Pathway:</b></h5>
                                              <h5 className="status d-flex">{item.pathwayName}</h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div >

                              </>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }

                  {
                    familyMembers.length > 0 &&
                    <div className='col-md-12 mt-3'>
                      <div className='profile-card-new pb-1'>
                        <div className='row'>
                          {
                            familyMembers.map((item, index) => {
                              return <>
                                <a style={{ cursor: 'pointer' }} className='col-md-3 mb-2 px-2' onClick={() => handleFamilyMemberProfileClick(item.membershipNumber)}>
                                  <div className='profile-box-n1'>
                                    <div className="info-line row">
                                      <div className='col'>
                                        <h3 className='name mb-2'>{item.memberName}</h3>
                                        <h5 className="program mb-0" ><b>Member ID:</b> {item.membershipNumber}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </a >
                              </>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }


                  {
                    distinctActions.length > 0 &&
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <div className="button-row">
                          <button className={`action-timelin-button blue-btn ${filterText.length <= 0 ? "action-timeline-active" : ""}`} onClick={() => setFilterText([])}>All</button>
                          {
                            distinctActions.map((item, index) => {
                              return <button key={index} className={`action-timelin-button ${filterText.includes(item) ? "action-timeline-active" : ""}`} onClick={() => handleTagClick(item)}>{item}</button>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }

                  <div className='col-md-12 my-3'>
                    <div className='comntent-MemberDeEnrollment profile-card-timeline mb-3'>
                      <div className="row">
                        <div className="col-md-12">
                          <div id="container-timeline">
                            <ol className="timeline-profile">
                              {
                                events.map((item, index) => {
                                  return <li className="call" >
                                    <time>
                                      <small>{item.readableDate}</small>
                                      <div className="big-profile">{item.action}</div>
                                    </time>
                                    <article>
                                      <p>{item.description}</p>
                                    </article>
                                  </li>
                                })
                              }

                            </ol>
                          </div>

                          {
                            eventsFound === false &&
                            <center><span>There are no records to display</span></center>
                          }

                          {showHealthLockerModal && <HealthLockerMP showHealthLockerModal={showHealthLockerModal} onHide={handleShowHealthLockerModal} handleCloseHealthLockerModal={handleCloseHealthLockerModal} memberBasicInfo={memberBasicInfo} />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MemberProfile;
