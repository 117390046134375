import { createSlice } from "@reduxjs/toolkit";


const cnHccTicketFilterSlice = createSlice({
    name: "cnHccTicketFilter",  
    initialState: {
        filter: { 
            searchTicketId: '',
            status: '',
            agent: "", 
            dates: { startDate: null, endDate: null }
        }, 
        sort: { 
            field: null,
            direction: null
        }
    },
    reducers: { 
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setSortConfig: (state, action) => { 
            state.sort = action.payload;
        }
    }
});

export const { setFilter, setSortConfig } = cnHccTicketFilterSlice.actions;
export default cnHccTicketFilterSlice.reducer;