import React from 'react'
import PageDescription from '../../Components/PageDescription/PageDescription'
import { Link } from 'react-router-dom';
import DropDownActionNew from '../../../componenents/ContentPrograms/DropDownActionNew';
import DataTable from 'react-data-table-component';


const HccReports = () => {

    const columns = [
        {
            name: 'Date Uploaded',
            selector: row => row.dateUploaded,
            sortable: true,
        },
        {
            name: 'Uploaded Member Records',
            selector: row => row.uploadedMembers,
            sortable: true,
        },
        {
            name: 'Successful Migration',
            selector: row => row.successfullyUploaded,
            sortable: true,
        },
        {
            name: 'Onboarded Members',
            selector: row => row.enrolledMembers,
            sortable: true,
        },
        {
            name: 'Uploaded By',
            selector: row => row.uploadedBy,
            sortable: true,
        },
        {
            name: 'Upload Notes',
            selector: row => row.notes,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.approvalStatus,
            sortable: true,
            cell: row => (
                <span style={{ color: row.color }}>
                    {row.approvalStatus}
                </span>
            ),
        },
        {
            name: 'Reviewed By',
            selector: row => row.lastActionBy,
            sortable: true,
        },
        {
            name: 'Review Date',
            selector: row => row.lastActionDate,
            sortable: true,
        },
        {
            name: 'Review Notes',
            selector: row => row.remarks,
            sortable: true,
        },
        {
            name: 'Options',
            selector: row => row.uploadId.id,
            sortable: true,
            cell: row => (
                <>
                    <Link to={`/file-insight/${row.uploadId.id}`} className='btn btn-Edit mx-3'><i className="bi bi-eye"></i></Link>
                    |
                    <DropDownActionNew uploadId={row.uploadId} />
                </>
            ),
        }
    ];

    return (
        <div className='ReportsPrograms'>
            <div className='container-fluid'>

                <div className="col-md ">
                    <h1 className="main-title">Migration Reports</h1>
                </div>

                <div className="col-md-3">

                </div>

                <div>
                    <PageDescription text={"You can raise new observations, share suggestions, or request enhancements to help us improve your experience. Track the progress of your submissions, view comments, and check the status of each observation right here."} image={"https://storage.googleapis.com/ksabupatest/2024/11/21/l4qs7/32llgolhd4.jpg"} />
                </div>

                
                <div className='reports-programs mb-3'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <DataTable 
                                    columns={columns}
                                    data={[]}
                                    pagination
                                    fixedHeader
                                    fixedHeaderScrollHeight="300px"
                                    highlightOnHover
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HccReports
