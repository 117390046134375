import { createSlice } from "@reduxjs/toolkit";
import { decryptData } from "../../pages/utils/Utils";
import moment from "moment";



const storedDates = localStorage.getItem("selectedDates")
? JSON.parse(decryptData(localStorage.getItem("selectedDates")))
: "";

let startDate = storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment(); 
let enddDate = storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment();


const hccTicketFilterSlice = createSlice({
    name: "hccTicketFilter",
    initialState: {
        filter: { 
            ticketId: '',
            status: '',
            agent: "", 
            dates: { startDate: startDate, endDate: enddDate }
        }, 
        sort: { 
            field: null,
            direction: null
        }
    },
    reducers: {
        setFilter: (state, action) => { state.filter = action.payload; },
        setSortConfig: (state, action) => { state.sort = action.payload; }
    }
});


export const { setFilter, setSortConfig } = hccTicketFilterSlice.actions;

export default hccTicketFilterSlice.reducer;