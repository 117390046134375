import { createSlice } from "@reduxjs/toolkit";


const paginationSlice = createSlice({
    name: "pagination",
    initialState: {
        observations: { 
            perPage: 10, 
            currentPage: 1, 
            totalEntries: 0, 
            totalPages: 0
        }, 

        hccTickets: { 
            perPage: 10, 
            currentPage: 1, 
            totalEntries: 0, 
            totalPages: 0
        }
    },
    reducers: {
        setObservationsPerPage: (state, action) => { state.observations.perPage = action.payload; },
        setObseravationsCurrentPage: (state, action) => { state.observations.currentPage = action.payload; },
        setObservationsTotalEntries: (state,action) => {state.observations.totalEntries = action.payload;},
        setObservationsTotalPages: (state,action) => {state.observations.totalPages = action.payload;},

        // hcc tickets
        setTicketPerPage: (state, action) => { state.hccTickets.perPage = action.payload; },
        setTicketCurrentPage: (state, action) => { state.hccTickets.currentPage = action.payload; },
        setTicketTotalEntries: (state,action) => {state.hccTickets.totalEntries = action.payload;},
        setTicketTotalPages: (state,action) => {state.hccTickets.totalPages = action.payload;},
        setTicketPagination: (state, action) => {state.hccTickets = action.payload;}
    },
});


export const { 
    setObservationsPerPage, 
    setObseravationsCurrentPage, 
    setObservationsTotalEntries, 
    setObservationsTotalPages, 
    setTicketPerPage, 
    setTicketCurrentPage, 
    setTicketTotalEntries, 
    setTicketTotalPages, 
    setTicketPagination
} = paginationSlice.actions;
export default paginationSlice.reducer;