import React, {useState, useEffect} from 'react'
import PageDescription from '../../Components/PageDescription/PageDescription';
import { fetch_all_ticket_types, save_ticket_type, delete_ticket_type, fetch_single_ticket_type, edit_ticket_type } from '../../../../services/hcc/Service';
import '../../css/hcc.css'; 
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { LoaderLoader } from '../../../utils/CommonLibrary';
import { Button, Modal } from 'react-bootstrap';
import { set } from 'firebase/database';




const AddTicketType = () => {

    const [ticketType, setTicketType] = useState("");
    const [ticketTypeTobeUpdated, setTicketTypeTobeUpdated] = useState("");
    const [requestInprogress, setRequestInprogress] = useState(false); 
    const [inputError, setInpputError] = useState(""); 
    const [updateInputError, setUpdateInputError] = useState(""); 
    const [isLoading, setIsLoading] = useState(false); 
    const [modalInfo, setModalInfo] = useState({ 
        show: false, 
        type: "", // only two posssible values : edit, delete
        id: "" // record id to be edited or deleted
    }); 
    


    const [allTicketTypes, setAllTicketTypes] = useState([]);
    
    const [columns] = useState([
        {
            name: 'Ticket Type',
            selector: row => row.ticket_type,
            sortable: true,
        },
        
        {
            name: 'Action',
            selector: row => row.action,
            cell: row => (
                <div><button  className="btn btn-Edit" onClick={() => triggerModal("edit", row.id)}><i className="bi bi-pencil"></i></button> | <button className="btn btn-Cancel"><i className="bi bi-trash" onClick={() => triggerModal("delete", row.id)}></i></button></div>
            ),
        },
    ]);

    useEffect(() => { 
        fetchAllTicketTypes();
    }, []); 

    async function saveTicketType(e){ 
        e.preventDefault();

        // validate input 
        if(!ticketType || ticketType.trim() === ""){ 
            setInpputError("Please enter ticket type"); 
            return; 
        }

        if(ticketType.length > 100){
            setInpputError("Ticket type cannot be more than 100 characters"); 
            return;
        }

        setRequestInprogress(true); 
        setIsLoading(true);
        let response; 
        try{ 
            response = await save_ticket_type({ticketType: ticketType}); 

            if(response?.data?.code === 200){ 
                setTicketType(""); 
                setInpputError(""); 
                toast.success(response?.data?.data?.message, {
                    position: 'top-right'});
                }; 

                fetchAllTicketTypes(); 
        }catch(e){ 
            let errorResponse = e?.response?.data; 
            
            if(errorResponse?.code < 500){ 
                setInpputError(errorResponse?.data?.message || "Something went wrong"); 
            }else { 
                setInpputError("Something went wrong");
            }
        
        }finally{   
            setRequestInprogress(false); 
            setIsLoading(false); 
        }
    }

    function handleInputChange (e){ 
        setTicketType(e.target.value); 
        setInpputError(""); 
    }

    async function fetchAllTicketTypes(){ 
        let response; 
        setIsLoading(true); 
        try{
            response = await fetch_all_ticket_types()
            setAllTicketTypes(response?.data?.data?.ticketTypes ||[]);
        }catch(e){ 

        }finally{ 
            setIsLoading(false); 
        }
    }

    // delete ticket type 
    async function deleteTicketType(id){ 
        setRequestInprogress(true); 

        try{ 
            let response = await delete_ticket_type(id); 
            if(response?.data?.code === 200){ 
                toast.success(response?.data?.data?.message, {
                    position: 'top-right'});
                fetchAllTicketTypes(); 

                setModalInfo({  
                    show: false,
                    type: "",
                    id: ""
                });
                
            }else { 
                toast.error(response?.data?.data?.message, {
                position: 'top-right'});
            }
        }catch(e){ 
            let errorResponse = e?.response?.data;
            if(errorResponse?.code < 500){ 
                toast.error(errorResponse?.data?.message, {
                    position: 'top-right'});
            }else { 
                toast.error("Something went wrong", {
                    position: 'top-right'});
            }
        }finally{ 
            setRequestInprogress(false); 
        }
    }

    function handleModalClose(){ 

        if(modalInfo.type === "edit"){ 
           setTicketTypeTobeUpdated("");
           setUpdateInputError("");
        }
        
        setModalInfo({
            show: false,
            type: "",
            id: "", 
        });
    }


    function triggerModal(type, id){ 
        
        if(type == "edit"){
            fetchSingleTicketType(id); 
        }

        setModalInfo({
            show: true,
            type: type,
            id: id
        });
    }


    async function fetchSingleTicketType (id){ 
        let response; 
        setIsLoading(true); 
        try{
            response = await fetch_single_ticket_type(id);

            if(response?.data?.code !== 200){ 
                toast.error(response?.data?.data?.message, {
                    position: 'top-right'});
                return;
            }   

            setTicketTypeTobeUpdated(response?.data?.data?.ticketType?.ticket_type);
        }catch(e){ 
            
        }finally{       
            setIsLoading(false);
        }
    }

    function updateTicketHandleChang(e){ 
        setTicketTypeTobeUpdated(e.target.value); 
        setUpdateInputError(""); 
    }


    async function updateTicketType(e){
        e.preventDefault();


        // validate the input 
        if(ticketTypeTobeUpdated == null || ticketTypeTobeUpdated.trim() === ""){ 
            setUpdateInputError("Please enter ticket type");
            return; 
        }


        if(ticketTypeTobeUpdated.length > 100){ 
            setUpdateInputError("Ticket type cannot be more than 100 characters"); 
            return;
        }

        setRequestInprogress(true); 
        setIsLoading(true);
        let response; 
        try{ 
            response = await edit_ticket_type({ticketType: ticketTypeTobeUpdated, ticketId: modalInfo.id}); 
            
            if(response?.data?.code === 200){
                toast.success(response?.data?.data?.message, {
                    position: 'top-right'});
                fetchAllTicketTypes(); 
                handleModalClose(); 
            }else { 
                toast.error(response?.data?.data?.message, {
                    position: 'top-right'});
            }
        }catch(e){
            let errorResponse = e?.response?.data;
            if(errorResponse?.code < 500){ 
                toast.error(errorResponse?.data?.message, {
                    position: 'top-right'});
            }else { 
                toast.error("Something went wrong", {
                    position: 'top-right'});
            }
        }finally{
            setRequestInprogress(false); 
            setIsLoading(false);
        }
    }

    return (
        <div className='container-fluid'>
            <ToastContainer />
            <LoaderLoader isLoading={isLoading} />
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md">
                    <h4 className="mb-0 content-main-title">Settings {">"} Add Ticket Type</h4>
                </div>
                <div className="col-md">
                    <div className="row g-3 align-items-center">
                    </div>
                </div>
            </div>

            <PageDescription text={"You can raise new observations, share suggestions, or request enhancements to help us improve your experience. Track the progress of your submissions, view comments, and check the status of each observation right here."} image={"https://storage.googleapis.com/ksabupatest/2024/11/21/l4qs7/32llgolhd4.jpg"} />

            <div className='comntent-UserManagement'>
                <div style={{marginLeft: '20px'}}>
                    <span>Add Ticket Type <span className='text-danger'>*</span></span>    
                    <div className='row mb-3'>

                        <div className='col-md-6 px-0'>
                            <div className="form-group d-flex align-items-start mt-2">
                               <div className='tw-w-3/4'>
                                    <input
                                        type="text"
                                        name="ticketId"
                                        placeholder="Enter Ticket Type"
                                        className="form-control input-field-ui"
                                        value={ticketType}
                                        onChange={e => handleInputChange(e)}
                                    />

                                    <p className='invalid-input'>{inputError}</p>
                                </div>

                                <button className='btn btn-primary clear-search-btn tw-ml-5 tw-align-top' onClick={e => saveTicketType(e)} disabled={requestInprogress}>Save </button>
                            </div>
                        </div>  
                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={allTicketTypes}
                    progressPending={requestInprogress}
                    progressComponent={<LoaderLoader />}
                    responsive= {true}
                    pagination={true}
                />
            </div>

            <Modal dialogClassName="modal-md" show={modalInfo.show} onHide={e => handleModalClose(e)} backdrop="static" keyboard={false} centered>
                <Modal.Header closeButton>
                
                </Modal.Header>
                <Modal.Body>
                    {
                        modalInfo.type === "delete" && (
                            <div className='tw-text-center'>
                                <h5>Are you sure you want to delete this ticket type?</h5>

                                <button className="btn btn-primary clear-search-btn mt-3" onClick={e => deleteTicketType(modalInfo.id)} > Yes, I want to delete </button>
                            </div>
                        )
                    }


                    { 
                        modalInfo.type === "edit" && 
                        <div className=''>
                            <h5>Edit Ticket Type</h5>

                            <div className="form-group d-flex align-items-start mt-2">
                                <div className='tw-w-3/4'>  
                                    <input
                                        type="text"
                                        name="ticketId"
                                        placeholder="Enter Ticket Type"
                                        className="form-control input-field-ui"
                                        value={ticketTypeTobeUpdated}
                                        onChange={e => updateTicketHandleChang(e)}
                                    />

                                    <p className='invalid-input text-danger'>{updateInputError}</p>
                                </div> 
                                <button className="btn btn-primary clear-search-btn" style={{marginLeft: "20px"}} 
                                    onClick={e => updateTicketType(e)}
                                > Save </button>
                            </div>
                        </div>
                    }

                </Modal.Body>   
            </Modal>
        </div>
    )
}

export default AddTicketType;